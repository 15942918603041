import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import { withRouter } from 'react-router-dom';
import { fetchUsers, fetchUsersCount } from '../../actions/users';
import qs from 'qs';
import * as _ from 'lodash';
import { formatDate } from '../../utils/utils';
import './user-table.css';

class UsersTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1,
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		fetchUsersCount().then((count) => this.setState({ pages: Math.ceil(count / 15) }));
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-edit fa-lg" />,
				Cell: (
					<button type="button" className="btn btn-outline-dark btn-sm">
						<i className="fas fa-user-edit fa-lg" />
					</button>
				),
				style: { background: 'white', padding: 0 },
				width: 55,
				resizable: false,
				sortable: false,
				filterable: false,
			},
			{
				id: 'firstName',
				Header: 'FIRST NAME',
				style: { textAlign: 'left' },
				accessor: 'firstName',
				width: 150,
				sortable: false,
 			},
			{
				id: 'lastName',
				Header: 'LAST NAME',
				style: { textAlign: 'left' },
				accessor: 'lastName',
				width: 200,
				sortable: false,
 			},
			{
				Header: 'USER PHONE',
				accessor: 'phone',
				style: { fontWeight: 'bold', textAlign: 'center' },
				width: 200,
				sortable: false,
			},
			{
				Header: 'USER E-MAIL',
				accessor: 'email',
				style: { fontWeight: 'bold' },
				width: 275,
				sortable: false,
			},
			{
				Header: 'STATUS',
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'status',
				Filter: ({ filter, onChange }) => (
					<select
						onChange={(event) => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="BANNED">BANNED</option>
						<option value="PENDING">PENDING</option>
						<option value="VERIFIED">VERIFIED</option>
					</select>
				),
				width: 120,
				resizable: false,
				sortable: false,
			},
			{
				Header: 'USER TYPE',
				style: { textAlign: 'center' },
				accessor: 'isRC',
				Cell: ({ value }) => (!!value && value === 'true' ? 'COMPANY' : 'PERSON'),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={(event) => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="true">COMPANY</option>
						<option value="false">PERSON</option>
					</select>
				),
				width: 150,
				resizable: false,
				sortable: false,
			},
			{
				Header: 'RATING',
				style: { textAlign: 'center', fontWeight: 'bold' },
				width: 80,
				Cell: ({ value }) => (!!value ? value : 0),
				accessor: 'rating.average',
				filterable: false,
			},
			{
				Header: 'REVIEWED',
				style: { textAlign: 'center' },
				accessor: 'reviewedBy',
				Cell: ({ value }) => (!!value ? <i className="fas fa-check fa-lg" /> : value),
				width: 100,
				resizable: false,
				filterable: false,
			},
			{
				id: 'createdAt',
				Header: 'Created Date & Time',
				style: { textAlign: 'center' },
				accessor: (d) => formatDate(d.createdAt),
				width: 160,
				resizable: false,
				filterable: false,
			},
		];
	}

	getRows() {
		return this.state.users;
	}

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.props.history.push('/admin/users/' + rowInfo.original.id);
			},
		};
	}

	refreshData(state) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page,
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach((filter) => {
			if (filter.id === 'phone') {
				filter.value = filter.value.replace(/[^0-9]/g, '');
			}
			query[filter.id] = filter.value;
		});
		fetchUsers(`?${qs.stringify(query)}`).then((users) => {
			this.setState({ users: users, loading: false });
		});
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			if (rowInfo.row.status === 'VERIFIED' && !!rowInfo.row.reviewedBy) {
				return {
					style: {
						background: 'rgba(124,252,0,0.5)',
					},
				};
			} else if (rowInfo.row.status === 'VERIFIED' && !rowInfo.row.reviewedBy) {
				return {
					style: {
						background: 'rgba(104,204,202,0.5)',
					},
				};
			} else if (rowInfo.row.status === 'BANNED') {
				return {
					style: {
						background: 'rgba(244,78,59,0.5)',
					},
				};
			} else {
				return {};
			}
		}
		return {};
	};

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-highlight"
					data={rows}
					pages={this.state.pages}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					//data={this.state.data} // should default to []
					//pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'createdAt', desc: true }]}
				/>
			</div>
		);
	}
}

export default withRouter(UsersTable);
