import React, { Component } from 'react';
import './dashboard.css';
import Header from '../global/header';
import { Link } from 'react-router-dom';


export default class Dashboard extends Component {
	render() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		if (currentUser.role === 'ADMIN') {
			return (
				<div className="dashboard">
					<div className="nav-container">
						<Header />
					</div>
					<div className="container-dash">
						<h1>Welcome to Giro Dashboard</h1>
						<span className="info-text-dash">Select one topic and start managing</span>
						<div className="option-container">
							<Link className="option" to={'/admin/users'}>
								<div className="icon">
									<i className="fas fa-users" />
								</div>
								<div className="name">USERS</div>
							</Link>
							<Link className="option" to={'/admin/cars'}>
								<div className="icon">
									<i className="fas fa-car" />
								</div>
								<div className="name">USER CARS</div>
							</Link>
							<Link className="option" to={'/admin/bookings'}>
								<div className="icon">
									<i className="fas fa-suitcase" />
								</div>
								<div className="name">BOOKINGS</div>
							</Link>
						</div>
						<div className="option-container">
							<Link className="option" to={'/admin/promo'}>
								<div className="icon">
									<i className="fas fa-percentage" />
								</div>
								<div className="name">PROMOTIONS</div>
							</Link>
							<Link className="option" to={'/admin/bpartner'}>
								<div className="icon">
									<i className="fas fa-handshake" />
								</div>
								<div className="name">PARTNERS</div>
							</Link>
							<Link className="option" to={'/admin/insurance'}>
								<div className="icon">
									<i className="fas fa-file" />
								</div>
								<div className="name">INSURANCE</div>
							</Link>
						</div>
					</div>
				</div>
			);
		} else if (currentUser.role === 'SUPPORT') {
			return (
				<div className="dashboard">
					<div className="nav-container">
						<Header />
					</div>
					<div className="container-dash">
						<h1>Welcome to Giro Dashboard</h1>
						<span className="info-text-dash">Select one topic and start managing</span>
						<div className="option-container">
							<Link className="option" to={'/admin/users'}>
								<div className="icon">
									<i className="fas fa-users" />
								</div>
								<div className="name">USERS</div>
							</Link>
							<Link className="option" to={'/admin/cars'}>
								<div className="icon">
									<i className="fas fa-car" />
								</div>
								<div className="name">USER CARS</div>
							</Link>
							<Link className="option" to={'/admin/bookings'}>
								<div className="icon">
									<i className="fas fa-suitcase" />
								</div>
								<div className="name">BOOKINGS</div>
							</Link>
						</div>
					</div>
				</div>
			);
		} else if (currentUser.role === 'MARKETING') {
			return (
				<div className="dashboard">
					<div className="nav-container">
						<Header />
					</div>
					<div className="container-dash">
						<h1>Welcome to Giro Dashboard</h1>
						<span className="info-text-dash">Select one topic and start managing</span>
						<div className="option-container">
							<Link className="option" to={'/admin/users'}>
								<div className="icon">
									<i className="fas fa-users" />
								</div>
								<div className="name">USERS</div>
							</Link>
							<Link className="option" to={'/admin/cars'}>
								<div className="icon">
									<i className="fas fa-car" />
								</div>
								<div className="name">USER CARS</div>
							</Link>
						</div>
						<div className="option-container">
							<Link className="option" to={'/admin/promo'}>
								<div className="icon">
									<i className="fas fa-percentage" />
								</div>
								<div className="name">PROMOTIONS</div>
							</Link>
							<Link className="option" to={'/admin/bpartner'}>
								<div className="icon">
									<i className="fas fa-handshake" />
								</div>
								<div className="name">PARTNERS</div>
							</Link>
						</div>
					</div>
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}
