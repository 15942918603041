import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../login/login';
import ProtectedRoute from './protectedRoute';
import Dashboard from '../dashboard/dashboard';
import Users from '../users/users';
import User from '../users/user';
import Cars from '../cars/cars';
import Car from '../cars/car';
import Booking from '../bookings/booking';
import Bookings from '../bookings/bookings';
import Promotion from '../promotions/promotion';
import Promotions from '../promotions/promotions';
import Partner from '../partners/partner';
import Partners from '../partners/partners';
import { ToastContainer } from 'react-toastify';

export default class AppRouter extends Component {
	render() {
		return (
			<div>
				<ToastContainer />
				<Router>
					<Switch>
						<Route exact path="/" component={Login} />

						<ProtectedLayout exact path="/admin/dashboard" component={Dashboard} />
						<ProtectedLayout exact path="/admin/users" component={Users} />
						<ProtectedLayout exact path="/admin/users/:userId" component={User} />
						<ProtectedLayout exact path="/admin/cars" component={Cars} />
						<ProtectedLayout exact path="/admin/cars/:carId" component={Car} />
						<ProtectedLayout exact path="/admin/bookings" component={Bookings} />
						<ProtectedLayout
							exact
							path="/admin/bookings/:bookingId"
							component={Booking}
						/>
						<ProtectedLayout exact path="/admin/promo" component={Promotions} />
						<ProtectedLayout exact path="/admin/promo/:promoId" component={Promotion} />
						<ProtectedLayout exact path="/admin/bpartner" component={Partners} />
						<ProtectedLayout
							exact
							path="/admin/bpartner/:partnerId"
							component={Partner}
						/>
						<Route component={NoMatch} />
					</Switch>
				</Router>
			</div>
		);
	}
}

const ProtectedLayout = ({ component: Component, ...rest }) => {
	return (
		<ProtectedRoute
			{...rest}
			isPublic={false}
			component={(matchProps) => (
				<div className="container">
					{/* <Header {...matchProps}/> */}
					<Component {...matchProps} />
					{/*<Footer/>*/}
				</div>
			)}
		/>
	);
};

const NoMatch = () => (
	<div className="container">
		<h1>404 Page not found</h1>
	</div>
);
