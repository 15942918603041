import React, { Component } from 'react';
import { formatDate } from '../../utils/utils';
import ListGroup from 'react-bootstrap/ListGroup';
import * as moment from 'moment';

export default class BookingPricing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cleaningFee: props.cleaningFee,
		};
	}

	componentDidMount() {}

	calculateTotalAdditionalCost(booking) {
		let total = 0;
		total += booking.car.pricing.additionalHour * booking.pricing.additionalCost.lateReturn;
		total += booking.car.pricing.additionalKm * booking.pricing.additionalCost.extraMileage;
		total += this.state.cleaningFee[booking.pricing.additionalCost.cleaningFee];
		if (booking.car.pricing.delivery) {
			total += booking.car.pricing.deliveryKm * booking.pricing.additionalCost.deliveryKm;
		}
		return total.toFixed(2);
	}

	getCleaningFee(booking) {
		let cleaningFeeText = booking.pricing.additionalCost.cleaningFee;
		return `${cleaningFeeText} = ${this.state.cleaningFee[cleaningFeeText]} \u20AC`;
	}

	getDeliveryKm(booking) {
		let deliveryKm = booking.pricing.additionalCost.deliveryKm;
		return `${deliveryKm} * ${booking.car.pricing.deliveryKm} \u20AC = ${
			deliveryKm * booking.car.pricing.deliveryKm
		} \u20AC`;
	}

	getExtraMileage(booking) {
		let extraMileage = booking.pricing.additionalCost.extraMileage;
		return `${extraMileage} * ${booking.car.pricing.additionalKm} \u20AC = ${
			extraMileage * booking.car.pricing.additionalKm
		} \u20AC`;
	}

	getLateReturn(booking) {
		let lateReturn = booking.pricing.additionalCost.lateReturn;
		return `${lateReturn} * ${booking.car.pricing.additionalHour} \u20AC = ${
			lateReturn * booking.car.pricing.additionalHour
		} \u20AC`;
	}

	getReleasedAmount(booking) {
		let releaseAmount = booking.car.pricing.depositAmount;
		if (booking.pricing.additionalCost) {
			releaseAmount -= this.calculateTotalAdditionalCost(booking);
		}
		return releaseAmount;
	}

	getReservedAmount(booking) {
		let reservedAmount = booking.car.pricing.depositAmount;
		if (booking.pricing.additionalCost && booking.pricing.additionalCost.status === 'PAID') {
			reservedAmount -= this.calculateTotalAdditionalCost(booking);
		}
		return reservedAmount;
	}

	getRentedDays(booking) {
		const from = moment(booking.from);
		const to = moment(booking.to);
		return to.diff(from, 'days');
	}

	getTotalAmount(booking) {
		let total = 0;
		total += booking.pricing.basePrice;
		total += booking.pricing.serviceFee;
		total /= this.getRentedDays(booking);
		total += booking.pricing.insuranceFee;
		return total.toFixed(2);
	}

	render() {
		let booking = this.props.booking;
		return (
			<div>
				<h5>
					<u>Security deposit</u>
				</h5>
				<ListGroup>
					<ListGroup.Item>
						<div className="user-data">
							<span className="label">
								<strong>Securit deposit status:</strong>
							</span>
							<h4>
								<span className="badge badge-pill badge-dark">
									<strong>{booking?.pricing?.securityDeposit?.status}</strong>
								</span>
							</h4>
						</div>

						{booking?.pricing?.securityDeposit?.status === 'PENDING' && (
							<div>
								<hr />
								<div className="user-data input-fields">
									<span className="label">
										<strong>Amount to reserve:</strong>
									</span>
									<span className="label">
										{booking?.car?.pricing?.depositAmount.toFixed(2)} &euro;
									</span>
								</div>
							</div>
						)}
						{booking?.pricing?.securityDeposit?.status === 'RELEASED' && (
							<div>
								<div className="label">
									<span className="label">
										<strong>Transaction ID:</strong>
									</span>
									<span className="label">
										{booking?.pricing?.securityDeposit?.transactionId}
									</span>
								</div>
								<hr />
								<div className="label">
									<span className="label">
										<strong>Released at:</strong>
									</span>
									<span className="label">
										{formatDate(
											booking?.pricing?.securityDeposit?.transactionTime
										)}
									</span>
								</div>
								<div className="label">
									<span className="label">
										<strong>Released amount:</strong>
									</span>
									<span className="label">
										{this.getReleasedAmount(booking).toFixed(2)} &euro;
									</span>
								</div>
							</div>
						)}
						{booking?.pricing?.securityDeposit?.status === 'RESERVED' && (
							<div>
								<div className="label">
									<span className="label">
										<strong>Transaction ID: </strong>
									</span>
									<span className="label">
										{booking?.pricing?.securityDeposit?.transactionId}
									</span>
								</div>
								<hr />
								<div className="label">
									<span className="label">
										<strong>Reserved at: </strong>
									</span>
									<span className="label">
										{formatDate(
											booking?.pricing?.securityDeposit?.transactionTime
										)}
									</span>
								</div>
								<div className="label">
									<span className="label">
										<strong>Reserved amount: </strong>
									</span>
									<span className="label">
										{this.getReservedAmount(booking).toFixed(2)} &euro;
									</span>
								</div>
							</div>
						)}
					</ListGroup.Item>
				</ListGroup>

				<h5>
					<u>Pricing</u>
				</h5>
				<ListGroup>
					<ListGroup.Item>
						{booking?.pricing ? (
							<div>
								<div className="user-data">
									<span className="label">
										<strong>Payment status:</strong>
									</span>
									<h4>
										<span className="badge badge-pill badge-dark">
											<strong>{booking?.pricing?.paymentStatus}</strong>
										</span>
									</h4>
								</div>

								<div className="user-data">
									<div className="label">
										<span className="label">
											<strong>Transaction ID: </strong>
										</span>
										<span className="label">
											{`${booking?.pricing?.transactionId}`}
										</span>
									</div>
								</div>

								<hr />
								<div className="user-data">
									<span className="label">
										<strong>Base price:</strong>
									</span>
									<span className="label">
										{booking?.pricing?.basePrice.toFixed(2) || 0} &euro;
									</span>
								</div>
								<div className="user-data">
									<span className="label">
										<strong>Giro fee:</strong>
									</span>
									<span className="label">
										{booking?.pricing?.serviceFee.toFixed(2) || 0} &euro;
									</span>
								</div>
								<div className="user-data">
									<span className="label">
										<strong>Insurance fee:</strong>
									</span>
									<span className="label">
										{booking?.pricing?.insuranceFee.toFixed(2) || 0} &euro;
									</span>
								</div>

								<div className="user-data">
									<span className="label"></span>
									<span className="label">
										{this.getTotalAmount(booking) +
											' € x ' +
											this.getRentedDays(booking) +
											' days'}
									</span>
								</div>
								<div className="user-data">
									<span className="label">
										<h4>
											<strong>Total:</strong>
										</h4>
									</span>
									<span className="label">
										<h4>
											<strong>
												{(
													this.getTotalAmount(booking) *
													this.getRentedDays(booking)
												).toFixed(2)}{' '}
												&euro;
											</strong>
										</h4>
									</span>
								</div>
							</div>
						) : (
							<div>Pricing not calculated.</div>
						)}
					</ListGroup.Item>
				</ListGroup>
				<h5>
					<u>Additional cost</u>
				</h5>
				<ListGroup>
					<ListGroup.Item>
						{booking?.pricing?.additionalCost ? (
							<div>
								<div className="user-data">
									<span className="label" style={{ marginLeft: '25px' }}>
										<strong>Payment status:</strong>
									</span>
									<h4>
										<span className="badge badge-pill badge-dark">
											<strong>
												{booking?.pricing?.additionalCost.status}
											</strong>
										</span>
									</h4>
								</div>
								<div className="user-data">
									{booking?.pricing?.additionalCost?.status === 'PAID' && (
										<div className="label" style={{ marginLeft: '25px' }}>
											<span className="label">
												<strong>Transaction ID: </strong>
											</span>
											<span className="label">
												{`${booking?.pricing?.additionalCost?.transactionId}`}
											</span>
										</div>
									)}
									{booking?.pricing?.additionalCost?.status === 'SUBMITTED' && (
										<div>
											<p>Waiting for user to accept additional cost</p>
										</div>
									)}
								</div>
								<hr />
								<p style={{ marginLeft: '25px' }}>
									<strong>Cleaning fee: </strong>
									{this.getCleaningFee(booking)}
								</p>
								<p style={{ marginLeft: '25px' }}>
									<strong>Delivery km: </strong>
									{this.getDeliveryKm(booking)}
								</p>
								<p style={{ marginLeft: '25px' }}>
									<strong>Extra mileage: </strong>
									{this.getExtraMileage(booking)}
								</p>
								<p style={{ marginLeft: '25px' }}>
									<strong>Late return: </strong>
									{this.getLateReturn(booking)}
								</p>
								<div className="user-data" style={{ marginLeft: '25px' }}>
									<span className="label">
										<h5>
											<strong>Total:</strong>
										</h5>
									</span>
									<span className="label">
										<h5>
											<strong>
												{this.calculateTotalAdditionalCost(booking)}
												&euro;
											</strong>
										</h5>
									</span>
								</div>
							</div>
						) : (
							<div>Additional cost was not submitted.</div>
						)}
					</ListGroup.Item>
				</ListGroup>
			</div>
		);
	}
}
