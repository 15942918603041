import React, { Component } from 'react';
import { imgixUrl } from '../../utils/utils';

export default class BookingCarImages extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	render() {
		let images = this.props.images;
		return (
			<div>
				{images && (
					<div className="container">
						<div className="row">
							<div className="col-6">
								<span>Front</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.front)}
									src={imgixUrl(images.front)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Front'}
								/>
							</div>
							<div className="col-6">
								<span>Right</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.right)}
									src={imgixUrl(images.right)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Right'}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<span>Back</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.back)}
									src={imgixUrl(images.back)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Back'}
								/>
							</div>
							<div className="col-6">
								<span>Left</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.left)}
									src={imgixUrl(images.left)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Left'}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<span>Interior</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.interior)}
									src={imgixUrl(images.interior)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Interior'}
								/>
							</div>
							<div className="col-6">
								<span>Mileage + Fuel</span>
								<img
									className="crs"
									onClick={() => this.openImage(images.mileageAndFuel)}
									src={imgixUrl(images.mileageAndFuel)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Mileage&Fuel'}
								/>
							</div>
						</div>

						<div className="row">
							<span>Additional images</span>
							{images.additional.map(image => (
								<img
									key={image}
									className="crs"
									onClick={() => this.openImage(image)}
									src={imgixUrl(image)}
									style={{ maxWidth: '200px', maxHeight: '150px' }}
									alt={'Additional'}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		);
	}
}
