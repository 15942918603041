import React, { Component } from 'react';
import { deleteCar, fetchCar, replaceFile } from '../../actions/cars';
import { imgixUrl } from '../../utils/utils';
import { patchUserCar } from '../../actions/users';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './car.css';

export default class Car extends Component {
	constructor(props) {
		super(props);

		this.onFileChange = this.onFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			car: {
				registrationNumber: '',
				VIN: '',
				brand: '',
				model: '',
				bodyType: '',
				user: {
					firstName: '',
					lastName: '',
				},
				imageUrls: [],
				documentImageUrl: '',
				newCarImages: '',
				estimatedValue: 0,
				firstRegistration: 0,
				basePremium: 0.0,
				idGeneralAgreement: '',
				insuranceCertificate: '',
				limitations: {},
				pricing: {},
			},
			file: '',
		};

		this.carId = props.match.params['carId'];
	}

	componentDidMount() {
		this.refreshCar();
	}

	refreshCar() {
		fetchCar(this.carId).then((car) => {
			let carData = { ...car };
			this.setState({ car: carData });
		});
	}

	handleUserClick(userId) {
		this.props.history.push('/admin/users/' + userId);
	}

	handleChangeCarStatus(carStatus) {
		patchUserCar(this.carId, this.state.car.userId, { status: carStatus })
			.then((res) => {
				this.refreshCar();
				toast.success('Car Status Changed');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	handleCarFieldChange(e, field) {
		let car = this.state.car;
		if (field === 'firstRegistration') {
			car[field] = moment(e).valueOf();
		} else {
			car[field] = e.target.value;
		}
		this.setState({ car: car });
	}

	handleSaveClick() {
		let payload = {
			estimatedValue: this.state.car.estimatedValue,
			bodyType: this.state.car.bodyType,
			isRR: this.state.car.isRR,
			firstRegistration: this.state.car.firstRegistration,
			registrationNumber: this.state.car.registrationNumber,
			VIN: this.state.car.VIN,
			brand: this.state.car.brand,
			model: this.state.car.model,
		};
		patchUserCar(this.carId, this.state.car.userId, payload)
			.then((updatedCar) => {
				this.setState({ car: updatedCar });
				toast.success('Car data updated');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	handleDeleteCar() {
		if (window.confirm('You sure you want to delete this car? (This action is irreversible)')) {
			deleteCar(this.carId)
				.then((res) => {
					toast.success('Car deleted');
					this.props.history.push('/admin/cars');
				})
				.catch((err) => {
					toast.error(err.response);
				});
		}
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	openPDF(base64PDF) {
		if (!base64PDF) return;
		const byteCharacters = atob(base64PDF);
		const byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const file = new Blob([byteArray], { type: 'application/pdf;base64' });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}

	onFileChange(e) {
		this.setState({ file: e.target.files[0] });
	}

	onSubmit(e, fileNum) {
		if (!this.state.file) {
			return;
		}
		e.preventDefault();
		replaceFile(this.carId, this.state.file, this.state.car.imageUrls[fileNum])
			.then((updatedCar) => {
				this.setState({ car: updatedCar });
				toast.success('Car image updated');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	render() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		let car = this.state.car;
		return (
			<div className="container-cars-details card-container">
				<Link className="option-user-details" to={'/admin/cars'}>
					<div className="back-btn">
						<i className="fas fa-long-arrow-alt-left" />
						Back
					</div>
				</Link>

				<hr />

				{car && (
					<div className="container">
						<div className="row">
							<div className="col">
								<h4>
									<u>Car details</u>
								</h4>
								<ListGroup>
									<ListGroup.Item>
										<div className="user-data input-fields">
											<span className="label">
												<strong>Owner: </strong>
											</span>
											<span
												className="label"
												onClick={() => this.handleUserClick(car.user.id)}
											>
												{car.user.firstName} {car.user.lastName}
											</span>
										</div>

										<div className="user-data">
											<span className="label">
												<strong>Remote rental:</strong>
											</span>

											<div className="radio">
												<label htmlFor="">Yes</label>
												<input
													type="radio"
													name="isRR"
													value="true"
													onChange={(e) =>
														this.handleChangeUserField(e, 'isRR')
													}
													checked={car.isRR === 'true'}
												/>
											</div>

											<div className="radio">
												<label htmlFor="">No</label>
												<input
													type="radio"
													name="isRR"
													value="false"
													onChange={(e) =>
														this.handleChangeUserField(e, 'isRR')
													}
													checked={car.isRR === 'false'}
												/>
											</div>
										</div>

										<div className="user-data input-fields">
											<span className="label">
												<strong>Brand:</strong>
											</span>
											<input
												value={car.brand || ''}
												onChange={(e) =>
													this.handleCarFieldChange(e, 'brand')
												}
											/>
										</div>

										<div className="user-data input-fields">
											<span className="label">
												<strong>Model:</strong>
											</span>
											<input
												value={car.model || ''}
												onChange={(e) =>
													this.handleCarFieldChange(e, 'model')
												}
											/>
										</div>

										<div className="user-data input-fields">
											<span className="label">
												<strong>Body type:</strong>
											</span>
											<input
												value={car.bodyType || ''}
												onChange={(e) =>
													this.handleCarFieldChange(e, 'bodyType')
												}
											/>
										</div>

										<div className="user-data">
											<span className="label">
												<strong>Registration number:</strong>
											</span>
											<input
												value={car.registrationNumber || ''}
												onChange={(e) =>
													this.handleCarFieldChange(
														e,
														'registrationNumber'
													)
												}
											/>
										</div>

										<div className="user-data">
											<span className="label">
												<strong>VIN:</strong>
											</span>
											<input
												value={car.VIN || ''}
												onChange={(e) =>
													this.handleCarFieldChange(e, 'VIN')
												}
											/>
										</div>

										<div className="user-data">
											<span className="label">
												<strong>Registration date:</strong>
											</span>
											<DatePicker
												selected={car.firstRegistration || 0}
												onChange={(e) =>
													this.handleCarFieldChange(
														e,
														'firstRegistration'
													)
												}
											/>
										</div>

										<div className="user-data">
											<span className="label">
												<strong>Estimated value:</strong>
											</span>
											<input
												value={car.estimatedValue || 0}
												onChange={(e) =>
													this.handleCarFieldChange(e, 'estimatedValue')
												}
											/>
										</div>
									</ListGroup.Item>
									<ListGroup.Item>
										<h5>
											<u>LIMITATIONS</u>
										</h5>
										<div className="user-data">
											<span className="label">
												<strong>Minimum age:</strong>
											</span>
											<span className="label">
												{car.limitations.minimumAge}
											</span>
										</div>
										<div className="user-data">
											<span className="label">
												<strong>Daily (km) included:</strong>
											</span>
											<span className="label">
												{car.limitations.dailyKmIncluded}
											</span>
										</div>
										<div className="user-data input-fields">
											{(currentUser.role === 'SUPPORT' ||
												currentUser.role === 'ADMIN') && (
												<div className="buttons">
													<button
														onClick={() => this.handleSaveClick()}
														className="btn btn-secondary save-btn"
														type="button"
													>
														Save
													</button>
													<button
														onClick={() => this.handleDeleteCar()}
														className="btn btn-danger save-btn del-btn"
														type="button"
													>
														Delete
													</button>
												</div>
											)}
										</div>
									</ListGroup.Item>
								</ListGroup>

								<hr />

								<div className="user-data input-fields">
									<span className="label">
										<strong>STATUS:</strong>
									</span>
									<h3>
										<span className="badge badge-pill badge-secondary">
											<strong>{car.status}</strong>
										</span>
									</h3>
									{(currentUser.role === 'SUPPORT' ||
										currentUser.role === 'ADMIN') && (
										<div className="buttons">
											<button
												className="btn btn-dark pull-right"
												onClick={() => this.handleChangeCarStatus('HIDDEN')}
											>
												Hide vehicle
											</button>
										</div>
									)}
								</div>

								<div className="user-data input-fields">
									{(currentUser.role === 'SUPPORT' ||
										currentUser.role === 'ADMIN') && (
										<div className="buttons">
											<button
												className="btn btn-warning"
												onClick={() =>
													this.handleChangeCarStatus('INVALID')
												}
											>
												Invalid
											</button>
											<button
												className="btn btn-primary"
												onClick={() =>
													this.handleChangeCarStatus('UNVERIFIED')
												}
											>
												Unverified
											</button>
											<button
												className="btn btn-success"
												onClick={() =>
													this.handleChangeCarStatus('VERIFIED')
												}
											>
												Verified
											</button>
										</div>
									)}
								</div>
							</div>

							<div className="col">
								<div className="row">
									<div className="col">
										<div className="document-header">
											<h4>
												<u>Car pricing</u>
											</h4>
										</div>
										<ListGroup>
											<ListGroup.Item>
												<div className="user-data input-fields">
													<span className="label">
														<strong>Daily price:</strong>
													</span>
													<span className="label">
														{car?.pricing?.dailyPrice} €
													</span>
												</div>
												<div className="user-data input-fields">
													<span className="label">
														<strong>Additional (km):</strong>
													</span>
													<span className="label">
														{car?.pricing?.additionalKm} €
													</span>
												</div>
												<div className="user-data input-fields">
													<span className="label">
														<strong>Additional (h):</strong>
													</span>
													<span className="label">
														{car?.pricing?.additionalHour} €
													</span>
												</div>
												{!!car.pricing.securityDeposit && (
													<div className="user-data input-fields">
														<span className="label">
															<strong>Sec. deposit:</strong>
														</span>
														<span className="label">
															{car?.pricing?.depositAmount} €
														</span>
													</div>
												)}
											</ListGroup.Item>
										</ListGroup>
									</div>
									<div className="col">
										<div className="document-header">
											<h4>
												<u>Car document image</u>
											</h4>
										</div>
										<ListGroup>
											<ListGroup.Item>
												<div className="document-container">
													<img
														className="crs"
														src={imgixUrl(car.documentImageUrl)}
														onClick={() =>
															this.openImage(car.documentImageUrl)
														}
														alt="Not uploaded"
													/>
												</div>
											</ListGroup.Item>
										</ListGroup>
									</div>
								</div>

								<h4>
									<u>Car insurance</u>
								</h4>

								<ListGroup>
									<ListGroup.Item>
										{car.insurance !== 'NONE' ? (
											<>
												<div className="user-data">
													<span className="label">
														<strong>Insurance Agreement: </strong>
													</span>
													<span className="label">
														{' '}
														{car.idGenAgreement || ''}
													</span>
												</div>
												<div className="user-data">
													<span className="label">
														<strong>Base insurance premium: </strong>
													</span>
													<span className="label">
														{car.basePremium.toFixed(2) || ''} €
													</span>
												</div>
												<div className="user-data">
													<span className="label">
														<strong>Insurance PDF: </strong>
													</span>
													<button
														className="btn btn-secondary"
														onClick={(event) =>
															this.openPDF(car.insuranceCertificate)
														}
													>
														View Insurance
													</button>
												</div>
											</>
										) : (
											<span className="label">
												Currently without additional insurance!
											</span>
										)}
									</ListGroup.Item>
								</ListGroup>
								{car && (
									<div className="document-container">
										<div className="car-images-header">
											<h4>
												<u>Showcase images</u>
											</h4>
											{(currentUser.role === 'SUPPORT' ||
												currentUser.role === 'ADMIN') && (
												<div className="form-group">
													<input
														type="file"
														onChange={this.onFileChange}
													/>
												</div>
											)}
										</div>
										<ListGroup>
											<div className="car-images">
												{car.imageUrls[0] && (
													<ListGroup.Item>
														<div className="car-images-container">
															<div className="row">
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[0]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[0]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 0)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[1]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[1]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 1)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[2]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[2]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 2)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</ListGroup.Item>
												)}
												{car.imageUrls[2] && (
													<ListGroup.Item>
														<div className="car-images-container">
															<div className="row">
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[3]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[3]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 3)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[4]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[4]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 4)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[5]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[5]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 5)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</ListGroup.Item>
												)}
												{car.imageUrls[5] && (
													<ListGroup.Item>
														<div className="car-images-container">
															<div className="row">
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[6]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[6]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 6)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[7]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[7]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 7)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
																<div className="car image">
																	<img
																		className="crs"
																		src={imgixUrl(
																			car.imageUrls[8]
																		)}
																		onClick={() =>
																			this.openImage(
																				car.imageUrls[8]
																			)
																		}
																		alt="Not uploaded"
																	/>
																	<form
																		onSubmit={(e) =>
																			this.onSubmit(e, 8)
																		}
																	>
																		<div className="form-group">
																			{(currentUser.role ===
																				'SUPPORT' ||
																				currentUser.role ===
																					'ADMIN') && (
																				<button
																					className="btn btn-primary"
																					type="submit"
																				>
																					Upload photo
																				</button>
																			)}
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</ListGroup.Item>
												)}
											</div>
										</ListGroup>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
