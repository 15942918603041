import React, { Component } from 'react';
import * as _ from 'lodash';
import { fetchBookings, fetchBookingsCount } from '../../actions/bookings';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { formatDate } from '../../utils/utils';
import './booking-table.css';

class BookingsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		fetchBookingsCount().then(count => this.setState({ pages: Math.ceil(count / 15) }));
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-edit fa-lg" />,
				Cell: (
					<button type="button" className="btn btn-outline-dark btn-sm">
						<i className="fas fa-search fa-lg" />
					</button>
				),
				style: { background: 'white', padding: 0 },
				width: 55,
				resizable: false,
				sortable: false,
				filterable: false
			},
			{
				id: 'warning',
				Header: <i className="fas fa-exclamation fa-lg" />,
				headerStyle: { textAlign: 'center' },
				style: { textAlign: 'center' },
				accessor: d => d,
				Cell: ({ value }) =>
					!!value.pricing.securityDeposit &&
					(value.pricing.securityDeposit.status === 'PENDING' ||
						value.pricing.securityDeposit.status === 'FAILED_RESERVE') ? (
						<i className="fas fa-exclamation-triangle fa-lg" />
					) : (
						''
					),
				width: 55,
				resizable: false,
				filterable: false
			},
			{
				id: 'customer',
				Header: 'CUSTOMER',
				resizable: false,
				width: 250,
				style: { textAlign: 'right' },
				accessor: d => d.user.firstName.toUpperCase() + ' ' + d.user.lastName.toUpperCase(),
				filterable: false
			},
			{
				id: 'owner',
				Header: 'OWNER',
				resizable: false,
				width: 250,
				style: { textAlign: 'right' },
				accessor: d =>
					d.car.user.firstName.toUpperCase() + ' ' + d.car.user.lastName.toUpperCase(),
				filterable: false
			},
			{
				Header: 'BOOKING CODE',
				width: 160,
				resizable: false,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'code'
			},
			{
				id: 'from',
				Header: 'START DATE',
				width: 160,
				resizable: false,
				accessor: d => formatDate(d.from),
				filterable: false
			},
			{
				id: 'to',
				Header: 'END DATE',
				width: 160,
				resizable: false,
				accessor: d => formatDate(d.to),
				filterable: false
			},
			{
				Header: 'STATUS',
				accessor: 'status',
				style: { textAlign: 'center', fontWeight: 'bold' },
				width: 120,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="PENDING">PENDING</option>
						<option value="APPROVED">APPROVED</option>
						<option value="BOOKED">BOOKED</option>
						<option value="DRIVING">DRIVING</option>
						<option value="FINISHED">FINISHED</option>
						<option value="CANCELED">CANCELED</option>
						<option value="REJECTED">REJECTED</option>
						<option value="TIMED_OUT">TIMED_OUT</option>
						<option value="NO_PICKUP">NO_PICKUP</option>
						<option value="DISPUTE">DISPUTE</option>
						<option value="FAILED">FAILED</option>
						<option value="DELETED">DELETED</option>
						<option value="ARCHIVED">ARCHIVED</option>
						<option value="RESERVED">RESERVED</option>
					</select>
				),
				resizable: false,
				sortable: false
			},
			{
				id: 'createdAt',
				Header: 'Created Date & Time',
				style: { textAlign: 'center' },
				accessor: d => formatDate(d.createdAt),
				width: 160,
				resizable: false,
				filterable: false
			}
		];
	}

	getRows() {
		return this.state.bookings;
	}

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.props.history.push('/admin/bookings/' + rowInfo.original.id);
			}
		};
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			switch (rowInfo.row.status) {
				case 'PENDING':
					return {
						style: {
							background: 'rgba(11,178,212,0.5)'
						}
					};
				case 'APPROVED':
					return {
						style: {
							background: 'rgba(243,156,18,0.8)'
						}
					};
				case 'BOOKED':
					return {
						style: {
							background: 'rgba(243,156,18,0.8)'
						}
					};
				case 'DRIVING':
					return {
						style: {
							background: 'rgba(62, 142, 247,0.8)'
						}
					};
				case 'FINISHED':
					return {
						style: {
							background: 'rgba(124,204,99,0.8)'
						}
					};
				case 'CANCELED':
					return {
						style: {
							background: 'rgba(189,195,199,0.6)'
						}
					};
				case 'TIMED_OUT':
					return {
						style: {
							background: 'rgba(189,195,199,0.6)'
						}
					};
				case 'NO_PICKUP':
					return {
						style: {
							background: 'rgba(189,195,199,0.6)'
						}
					};
				case 'REJECTED':
					return {
						style: {
							background: 'rgba(231,76,60,0.5)'
						}
					};
				case 'DISPUTE':
					return {
						style: {
							background: 'rgba(231,76,60,0.5)'
						}
					};
				case 'FAILED':
					return {
						style: {
							background: 'rgba(231,76,60,0.5)'
						}
					};
				case 'DELETED':
					return {
						style: {
							background: 'rgba(231,76,60,0.8)'
						}
					};
				case 'ARCHIVED':
					return {
						style: {
							background: 'rgba(38,25,38,0.5)'
						}
					};
				case 'RESERVED':
					return {
						style: {
							background: 'rgba(127,102,255,0.8)'
						}
					};
				default:
					return {};
			}
		}
		return {};
	};

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach(filter => {
			query[filter.id] = filter.value;
		});
		fetchBookings(`?${qs.stringify(query)}`).then(bookings => {
			this.setState({ bookings: bookings, loading: false });
		});
	}

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-highlight"
					data={rows}
					pages={this.state.pages}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					// data={this.state.data} // should default to []
					// pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'createdAt', desc: true }]}
				/>
			</div>
		);
	}
}

export default withRouter(BookingsTable);
