import React, { Component } from 'react';
import CarsTable from './carsTable';
import { toast } from 'react-toastify';
import Header from '../global/header';
import './cars.css';

export default class Cars extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cars: null
		};
	}

	componentDidMount() {}

	handleSth() {
		toast.success('I am success!!');
	}

	render() {
		return (
			<div className="dashboard">
				<div className="nav-container">
					<Header />
				</div>
				<div className="container-users card-container">
					<h3>VEHICLE LIST</h3>
					<hr />
					<CarsTable />
				</div>
			</div>
		);
	}
}
