import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchCars(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/cars${query}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchCar(carId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/cars/${carId}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchCarsCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/cars/count`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function deleteCar(carId) {
	return new Promise((resolve, reject) => {
		axios.delete(`${API_URL}/cars/${carId}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function replaceFile(carId, file, oldName) {
	let name;
	if (!oldName) {
		/**if it is a NEW file */
		name = 'NEW_' + Date.now().toString(16) + '.jpg';
	} else {
		/**if it REPLACES file */
		oldName = oldName.substring(oldName.lastIndexOf('/') + 1);
		oldName = oldName.substring(0, oldName.lastIndexOf('?'));
		if (oldName.includes('REPLACE_')) {
			name = oldName;
		} else {
			name = 'REPLACE_' + oldName;
		}
	}
	const data = new FormData();
	data.append('file', file, name);
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/users/${carId}/replaceFile`, data, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}
