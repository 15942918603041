import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchBookings(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings${query}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchBooking(bookingId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/${bookingId}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchUserBookings(userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}/bookings`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchCleaningFee() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/cleaningFee`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchBookingsCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/count`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function confirmDeposit(id) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/${id}/confirmSecDeposit`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function changePayment(id) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/${id}/changePayment`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function deleteBooking(id) {
	return new Promise((resolve, reject) => {
		axios.delete(`${API_URL}/bookings/${id}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function sendContractsBooking(id) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bookings/${id}/sendContract`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}
