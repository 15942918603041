import * as moment from 'moment';

export function imgixUrl(imageUrl) {
	if (imageUrl != null && imageUrl.includes('auto=compress')) {
		if (imageUrl.includes('&w=720')) {
			return imageUrl.replace('&w=720', '&w=250');
		} else {
			return imageUrl + '&w=250';
		}
	}
	return imageUrl + '?w=250';
}

export function formatDate(timestamp) {
	return moment(timestamp).format('DD.MM.YYYY HH:mm');
}
