import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import { withRouter } from 'react-router-dom';
import { fetchPartners, fetchPartnerCount } from '../../actions/partners';
import * as _ from 'lodash';
import { formatDate } from '../../utils/utils';
import './partner-table.css';

class PartnersTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		fetchPartnerCount().then((count) => this.setState({ pages: Math.ceil(count / 15) }));
	}

	getColumns() {
		return [
			{
				Header: 'Code',
				accessor: 'code',
				sortable: false,
				filterable: false
			},
			{
				Header: 'Value',
				accessor: 'value',
				sortable: false,
				filterable: false
			},
			{
				Header: 'UsageLimit',
				accessor: 'usageLimit',
				sortable: false,
				filterable: false
			},
			{
				id: 'validFrom',
				Header: 'Valid_From',
				accessor: (d) => formatDate(d.validFrom),
				filterable: false
			},
			{
				id: 'validTo',
				Header: 'Valid_To',
				accessor: (d) => formatDate(d.validTo),
				filterable: false
			},
			{
				id: 'createdAt',
				Header: 'Created',
				accessor: (d) => formatDate(d.createdAt),
				filterable: false
			}
		];
	}

	getRows() {
		return this.state.partners;
	}

	handleDoubleClickOnRow(rowInfo) {
		return {
			onDoubleClick: () => {
				this.props.history.push('/admin/bpartner/' + rowInfo.original.id);
			}
		};
	}

	refreshData(state) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach((filter) => {
			query[filter.id] = filter.value;
		});
		fetchPartners().then((partner) => {
			this.setState({ partner: partner, loading: false });
		});
	}

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-striped -highlight"
					data={rows}
					pages={this.state.pages}
					columns={columns}
					getTrProps={(state, rowInfo) => {
						return this.handleDoubleClickOnRow(rowInfo);
					}}
					// data={this.state.data} // should default to []
					// pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'validFrom', desc: true }]}
				/>
			</div>
		);
	}
}

export default withRouter(PartnersTable);
