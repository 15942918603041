import React, { Component } from 'react';
import PromosTable from './promosTable';
import Header from '../global/header';
import { createPromo } from '../../actions/promotions';
import { toast } from 'react-toastify';

export default class Promotions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilter: {}
		};
	}

	componentDidMount() {}

	handleCreatePromo() {
		createPromo()
			.then((res) => {
				toast.success('Promotion created');
				window.location.reload();
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	render() {
		return (
			<div className="dashboard">
				<div className="nav-container">
					<Header />
				</div>
				<div className="container-promos card-container">
					<div className="promo-data input-fields">
						<div className="buttons">
							<button
								onClick={() => this.handleCreatePromo()}
								className="btn btn-primary save-btn"
							>
								Create new promotion
							</button>
						</div>
					</div>
					<h3>Promotions</h3>

					<hr />
					<PromosTable />
				</div>
			</div>
		);
	}
}
