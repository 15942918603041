import React, { Component } from 'react';
import { fetchPartner, updatePartner } from '../../actions/partners';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './partner-details.css';
import * as moment from 'moment';

class Partner extends Component {
	constructor(props) {
		super(props);

		this.state = {
			partner: null
		};
		this.partnerId = props.match.params['partnerId'];
	}

	componentDidMount() {
		this.refreshPartner();
	}

	refreshPartner() {
		fetchPartner(this.partnerId).then((partner) => {
			this.setState({ partner: partner });
		});
	}

	handleChangePartnerField(e, field) {
		let partner = { ...this.state.partner };
		if (field === 'validFrom' || field === 'validTo') {
			partner[field] = moment(e).valueOf();
		} else {
			partner[field] = e.target.value;
		}
		this.setState({ partner: partner });
	}

	handleSavePartnerFields() {
		let payload = {
			code: this.state.promotion.code,
			text: this.state.promotion.text,
			value: parseInt(this.state.promotion.value),
			usageNumber: parseInt(this.state.promotion.usageNumber),
			usageLimit: parseInt(this.state.promotion.usageLimit),
			validFrom: moment(this.state.promotion.validFrom, 'DD.MM.YYYY').valueOf() || 0,
			validTo: moment(this.state.promotion.validTo, 'DD.MM.YYYY').valueOf() || 0
		};

		updatePartner(this.partnerId, payload)
			.then((updatedPartner) => {
				this.setState({ partner: updatedPartner });
				toast.success('Partner successfully updated.');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	render() {
		let promotion = this.state.promotion;
		return (
			<div className="container-partners-details card-container">
				<Link className="option-partners-details" to={'/admin/bpartner'}>
					<div className="back-btn">
						<i className="fas fa-long-arrow-alt-left" />
						Back
					</div>
				</Link>
				<hr />

				{promotion && (
					<div>
						<div className="container">
							<div className="row">
								<div className="col">
									<h4>
										<u>Partner details</u>
									</h4>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Code:</strong>
										</span>
										<input
											value={promotion.code || ''}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'code')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Text:</strong>
										</span>
										<input
											value={promotion.text || ''}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'text')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Value (% or €):</strong>
										</span>
										<input
											type="number"
											value={promotion.value || 0}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'value')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Usage number:</strong>
										</span>
										<input
											type="number"
											value={promotion.usageNumber || 0}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'usageNumber')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Usage limit:</strong>
										</span>
										<input
											type="number"
											value={promotion.usageLimit || 0}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'usageLimit')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Valid from:</strong>
										</span>

										<DatePicker
											selected={promotion.validFrom || 0}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'validFrom')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Valid to:</strong>
										</span>

										<DatePicker
											selected={promotion.validTo || 0}
											onChange={(e) =>
												this.handleChangePartnerField(e, 'validTo')
											}
										/>
									</div>

									<hr />

									<div className="user-data input-fields">
										<div className="buttons">
											<button
												onClick={() => this.handleSavePartnerFields()}
												className="btn btn-secondary save-btn"
											>
												Save
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(Partner);
