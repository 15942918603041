import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../actions/auth';
import './header.css';


export default class Header extends Component {
	handleLogout() {
		logoutUser();
		this.props.history.push('/');
	}

	render() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		if (currentUser.role === 'ADMIN') {
			return (
				<nav>
					<ul className="nav">
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/users'}>
								<i className="fas fa-users" /> Users
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/cars'}>
								<i className="fas fa-car" /> Cars
							</Link>
						</li>

						<li className="nav-item">
							<Link className="nav-link" to={'/admin/bookings'}>
								<i className="fas fa-suitcase" /> Bookings
							</Link>
						</li>

						<li className="nav-item">
							<Link className="nav-link" to={'/admin/promo'}>
								<i className="fas fa-percentage" /> Promotions
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/bpartner'}>
								<i className="fas fa-handshake" /> Partners
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/insurance'}>
								<i className="fas fa-file" /> Insurance
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link logout" to={'/'}>
								<i className="fas fa-sign-out-alt" /> Logout
							</Link>
						</li>
					</ul>
				</nav>
			);
		} else if (currentUser.role === 'SUPPORT') {
			return (
				<nav>
					<ul className="nav">
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/users'}>
								<i className="fas fa-users" /> Users
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/cars'}>
								<i className="fas fa-car" /> Cars
							</Link>
						</li>

						<li className="nav-item">
							<Link className="nav-link" to={'/admin/bookings'}>
								<i className="fas fa-suitcase" /> Bookings
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link logout" to={'/'}>
								<i className="fas fa-sign-out-alt" /> Logout
							</Link>
						</li>
					</ul>
				</nav>
			);
		} else if (currentUser.role === 'MARKETING') {
			return (
				<nav>
					<ul className="nav">
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/users'}>
								<i className="fas fa-users" /> Users
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/cars'}>
								<i className="fas fa-car" /> Cars
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/promo'}>
								<i className="fas fa-percentage" /> Promotions
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={'/admin/bpartner'}>
								<i className="fas fa-handshake" /> Partners
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link logout" to={'/'}>
								<i className="fas fa-sign-out-alt" /> Logout
							</Link>
						</li>
					</ul>
				</nav>
			);
		} else {
			return <div></div>;
		}
	}
}
