import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { fetchCars, fetchCarsCount } from '../../actions/cars';
import { formatDate } from '../../utils/utils';
import './cars-table.css';

class CarsTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1
		};

		this.refreshData = this.refreshData.bind(this);
	}

	componentDidMount() {
		fetchCarsCount().then(count => this.setState({ pages: Math.ceil(count / 15) }));
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-edit fa-lg" />,
				Cell: (
					<button type="button" className="btn btn-outline-dark btn-sm">
						<i className="fas fa-pencil-alt fa-lg" />
					</button>
				),
				style: { background: 'white', padding: 0 },
				width: 55,
				resizable: false,
				sortable: false,
				filterable: false
			},
			{
				Header: 'BRAND',
				width: 120,
				resizable: false,
				accessor: 'brand'
			},
			{
				Header: 'MODEL',
				width: 120,
				resizable: false,
				accessor: 'model'
			},
			{
				Header: 'REG. NUMBER',
				width: 160,
				resizable: false,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'registrationNumber'
			},
			{
				Header: 'TYPE',
				accessor: 'bodyType',
				style: { textAlign: 'center' },
				width: 200,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="CONVERTIBLE">CONVERTIBLE</option>
						<option value="COUPE">COUPE</option>
						<option value="SUV">SUV</option>
						<option value="OFF_ROAD">OFF_ROAD</option>
						<option value="SEDAN">SEDAN</option>
						<option value="STATION_WAGON">STATION_WAGON</option>
						<option value="TRANSPORTER">TRANSPORTER</option>
						<option value="VAN">VAN</option>
						<option value="OTHER">OTHER</option>
					</select>
				),
				resizable: false,
				sortable: false
			},
			{
				id: 'name',
				Header: 'OWNER NAME',
				width: 250,
				style: { textAlign: 'right' },
				resizable: false,
				accessor: d => d.user.firstName.toUpperCase() + ' ' + d.user.lastName.toUpperCase(),
				sortable: false,
				filterable: false
			},
			{
				Header: 'STATUS',
				accessor: 'status',
				style: { textAlign: 'center', fontWeight: 'bold' },
				width: 120,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="UNVERIFIED">Unverified</option>
						<option value="INVALID">Invalid</option>
						<option value="VERIFIED">Verified</option>
						<option value="HIDDEN">Hidden</option>
					</select>
				),
				resizable: false,
				sortable: false
			},
			{
				Header: 'RATING',
				style: { textAlign: 'center', fontWeight: 'bold' },
				width: 80,
				Cell: ({ value }) => (!!value ? value : 0),
				accessor: 'rating.average',
				resizable: false,
				filterable: false
			},
			{
				Header: 'INSURED',
				style: { textAlign: 'center' },
				accessor: 'insurance',
				Cell: ({ value }) => (!!value ? <i className="fas fa-check fa-lg" /> : value),
				width: 100,
				resizable: false,
				filterable: false
			},
			{
				id: 'createdAt',
				Header: 'Created Date & Time',
				style: { textAlign: 'center' },
				accessor: d => formatDate(d.createdAt),
				width: 160,
				resizable: false,
				filterable: false
			}
		];
	}

	getRows() {
		return this.state.cars;
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			if (rowInfo.row.status === 'VERIFIED') {
				return {
					style: {
						background: 'rgba(124,252,0,0.5)'
					}
				};
			} else if (rowInfo.row.status === 'HIDDEN') {
				return {
					style: {
						background: 'rgba(104,204,202,0.5)'
					}
				};
			} else if (rowInfo.row.status === 'INVALID') {
				return {
					style: {
						background: 'rgba(244,78,59,0.5)'
					}
				};
			} else {
				return {};
			}
		}
		return {};
	};

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.props.history.push('/admin/cars/' + rowInfo.original.id);
			}
		};
	}

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach(filter => {
			query[filter.id] = filter.value;
		});
		fetchCars(`?${qs.stringify(query)}`).then(cars => {
			this.setState({ cars: cars, loading: false });
		});
	}

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-highlight"
					data={rows}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					pages={this.state.pages}
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'createdAt', desc: true }]}
				/>
			</div>
		);
	}
}

export default withRouter(CarsTable);
