import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchPartners() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bp`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function createPartner(partnerId) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/bp/${partnerId}/createBP`, {}, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchPartnerCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bp/count`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchPartner(partnerId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/bp/${partnerId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function updatePartner(partnerId, payload) {
	return new Promise((resolve, reject) => {
		axios.patch(`${API_URL}/bp/${partnerId}`, payload, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}
