import axios from 'axios';

let API_URL = process.env.REACT_APP_API_HOST;

export function login(email, password) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/users/loginAdmin`, { email: email, password: password })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function signin(email) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/users/signinAdmin`, { email: email, password: 'password' })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function logoutUser() {
	localStorage.removeItem('accessToken');
	localStorage.removeItem('user');
}
