import React, { Component } from 'react';
import {
	deleteBooking,
	fetchBooking,
	confirmDeposit,
	changePayment,
	fetchCleaningFee,
	sendContractsBooking,
} from '../../actions/bookings';
import { formatDate } from '../../utils/utils';
import BookingCarImages from './bookingCarImages';
import BookingPricing from './bookingPricing';
import { toast } from 'react-toastify';
import ListGroup from 'react-bootstrap/ListGroup';
import './booking.css';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

export default class Booking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			booking: null,
			showBeforeImages: false,
			showAfterImages: false,
		};

		this.bookingId = props.match.params['bookingId'];
	}

	componentDidMount() {
		Promise.all([fetchCleaningFee(), fetchBooking(this.bookingId)]).then((res) => {
			this.setState({ cleaningFee: res[0], booking: res[1] });
		});
	}

	selectUser(userId) {
		this.props.history.push(`/admin/users/${userId}`);
	}

	confirmDeposit(event) {
		event.preventDefault();
		confirmDeposit(this.bookingId)
			.then((updatedBooking) => {
				this.setState({ booking: updatedBooking });
				toast.success('Security Deposit Confirmed');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	changePayment(event) {
		event.preventDefault();
		changePayment(this.bookingId)
			.then((updatedBooking) => {
				this.setState({ booking: updatedBooking });
				toast.success('Booking Payment Changed');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	doDelete(event) {
		event.preventDefault();
		deleteBooking(this.bookingId)
			.then((updatedBooking) => {
				this.setState({ booking: updatedBooking });
				toast.success('Booking Deleted');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	sendContracts(event) {
		event.preventDefault();
		sendContractsBooking(this.bookingId);
	}

	openPDF(base64PDF) {
		if (!base64PDF) return;
		const byteCharacters = atob(base64PDF);
		const byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const file = new Blob([byteArray], { type: 'application/pdf;base64' });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}

	render() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		let booking = this.state.booking;
		return (
			<div className="container-cars-details card-container">
				<Link className="option-user-details" to={'/admin/bookings'}>
					<div className="back-btn">
						<i className="fas fa-long-arrow-alt-left" />
						Back
					</div>
				</Link>
				<hr />
				{booking && (
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="row">
									<div className="col">
										<h4>
											<u>Booking details</u>
										</h4>
										<ListGroup>
											<ListGroup.Item>
												<div className="user-data">
													<span className="label">
														<strong>Code:</strong>
													</span>
													<h4>
														<span className="badge  badge-light">
															<strong>{booking.code}</strong>
														</span>
													</h4>
												</div>

												<div className="user-data input-fields">
													<span className="label">
														<strong>STATUS:</strong>
													</span>
													<h4>
														<span className="badge badge-pill badge-secondary">
															<strong>{booking.status}</strong>
														</span>
													</h4>
												</div>

												<div className="user-data input-fields">
													<span className="label">
														<strong>Payment method:</strong>
													</span>
													<h4>
														<span className="badge badge-pill badge-dark">
															<strong>
																{booking.paymentMethod === 'INV'
																	? 'INVOICE'
																	: 'CARD'}
															</strong>
														</span>
													</h4>
												</div>

												<div className="user-data input-fields">
													<span className="label">
														<strong>Vehicle:</strong>
													</span>
													<span className="label">
														{booking?.car?.brand +
															' ' +
															booking?.car?.model}
													</span>
												</div>

												{booking?.car?.insurance !== 'NONE' && (
													<div className="user-data input-fields">
														<span className="label">
															<strong>Vehicle insurance:</strong>
														</span>
														<span className="label">
															{`${
																booking?.car?.basePremium.toFixed(
																	2
																) || ''
															} €`}
														</span>
													</div>
												)}
												<hr />
												<div className="user-data">
													<span className="label">
														<strong>Booking start:</strong>
													</span>
													<span className="label">
														{formatDate(booking.from)}
													</span>
												</div>
												<div className="user-data">
													<span className="label">
														<strong>Booking end:</strong>
													</span>
													<span className="label">
														{formatDate(booking.to)}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>Mileage done:</strong>
													</span>
													<span className="label">
														{`${
															booking?.afterImages?.currentMileage -
															booking?.beforeImages?.currentMileage
														} km`}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>Time done:</strong>
													</span>
													<span className="label">
														{`${Math.floor(
															moment
																.duration(
																	moment(
																		booking?.history
																			?.ownerDropOffTime
																	).diff(
																		moment(
																			booking?.history
																				?.ownerPickupTime
																		)
																	)
																)
																.asDays()
														)} days ${Math.ceil(
															moment
																.duration(
																	moment(
																		booking?.history
																			?.ownerDropOffTime
																	).diff(
																		moment(
																			booking?.history
																				?.ownerPickupTime
																		)
																	)
																)
																.asHours() % 24
														)} hours`}
													</span>
												</div>
											</ListGroup.Item>
										</ListGroup>
										<div className="user-data input-fields">
											{(currentUser.role === 'SUPPORT' ||
												currentUser.role === 'ADMIN') && (
												<div className="buttons">
													<button
														className="btn btn-success"
														onClick={(event) =>
															this.confirmDeposit(event)
														}
													>
														Confirm Sec. Deposit
													</button>
													<button
														className="btn btn-info"
														onClick={(event) =>
															this.sendContracts(event)
														}
													>
														Resend CONTRACTS
													</button>
												</div>
											)}
											{currentUser.role === 'ADMIN' && (
												<div className="buttons">
													<button
														className="btn btn-danger"
														onClick={(event) => this.doDelete(event)}
													>
														Delete
													</button>
												</div>
											)}
										</div>
									</div>

									<div className="col">
										<ListGroup>
											<ListGroup.Item>
												<h5>
													<u>VIEW DOCUMENTS</u>
												</h5>
												<div className="buttons">
													<button
														className="btn btn-secondary"
														onClick={(event) =>
															this.openPDF(
																booking?.documents?.invoicePDF
															)
														}
													>
														View Invoice
													</button>
													<button
														className="btn btn-secondary"
														onClick={(event) =>
															this.openPDF(
																booking?.documents?.contractPDF
															)
														}
													>
														View Contract
													</button>
													<button
														className="btn btn-secondary"
														onClick={(event) =>
															this.openPDF(
																booking?.documents?.receiptPDF
															)
														}
													>
														View Receipt
													</button>
												</div>
											</ListGroup.Item>
											<ListGroup.Item>
												<h5>
													<u>Pick-up times</u>
												</h5>
												<div className="user-data">
													<span className="label">
														<strong>Customer time:</strong>
													</span>
													<span className="label">
														{formatDate(
															booking?.history?.customerPickupTime
														)}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>Owner time*:</strong>
													</span>
													<span className="label">
														{formatDate(
															booking?.history?.ownerPickupTime
														)}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>Start mileage:</strong>
													</span>
													<span className="label">
														{`${booking?.beforeImages?.currentMileage} km`}
													</span>
												</div>
											</ListGroup.Item>
											<ListGroup.Item>
												<h5>
													<u>Drop-off times</u>
												</h5>
												<div className="user-data">
													<span className="label">
														<strong>Owner time*:</strong>
													</span>
													<span className="label">
														{formatDate(
															booking?.history?.ownerDropOffTime
														)}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>Customer time:</strong>
													</span>
													<span className="label">
														{formatDate(
															booking?.history?.customerDropOffTime
														)}
													</span>
												</div>

												<div className="user-data">
													<span className="label">
														<strong>End mileage:</strong>
													</span>
													<span className="label">
														{`${booking?.afterImages?.currentMileage} km`}
													</span>
												</div>
											</ListGroup.Item>
										</ListGroup>
									</div>

									<div className="col">
										<h4>
											<u>Booking pricing</u>
										</h4>
										<BookingPricing
											booking={booking}
											cleaningFee={this.state.cleaningFee}
										/>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col">
										<div className="car-images">
											<div className="images-before">
												<h5
													className="crs"
													onClick={() =>
														this.setState({
															showBeforeImages: !this.state
																.showBeforeImages,
														})
													}
												>
													Before images &nbsp;&nbsp;
													{!this.state.showBeforeImages && (
														<i className="fas fa-chevron-down" />
													)}
													{this.state.showBeforeImages && (
														<i className="fas fa-chevron-up" />
													)}
												</h5>
												{this.state.showBeforeImages && (
													<BookingCarImages
														images={booking.beforeImages}
													/>
												)}
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="car-images">
											<div className="images-after">
												<h5
													className="crs"
													onClick={() =>
														this.setState({
															showAfterImages: !this.state
																.showAfterImages,
														})
													}
												>
													After images &nbsp;&nbsp;
													{!this.state.showAfterImages && (
														<i className="fas fa-chevron-down" />
													)}
													{this.state.showAfterImages && (
														<i className="fas fa-chevron-up" />
													)}
												</h5>
												{this.state.showAfterImages && (
													<BookingCarImages
														images={booking.afterImages}
													/>
												)}
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col">
										<h4>Owner</h4>

										<div className="user-data">
											<span
												className="label"
												onClick={() => this.selectUser(booking.car.userId)}
											>
												Name: {booking.car.user.firstName}{' '}
												{booking.car.user.lastName}
											</span>
										</div>

										{/* <div className="user-data">
                                        <span className="label">Email: <a
                                            href={"mailto:" + booking.car.user.notificationEmail} target="_top"
                                            className="email-link">{booking.car.user.notificationEmail}</a></span>
                                    </div>

                                    <div className="user-data">
                                        <span className="label">Phone: <a href={"tel:" + booking.car.user.phone}
                                                                          className="phone"><i
                                            className="fas fa-phone"/></a></span>
                                    </div> */}
									</div>

									<div className="col">
										<h4>Customer</h4>
										<div className="user-data">
											<span
												className="label"
												onClick={() => this.selectUser(booking.userId)}
											>
												Name: {booking.user.firstName}{' '}
												{booking.user.lastName}
											</span>
										</div>

										{/* <div className="user-data">
                                        <span className="label">Email: <a
                                            href={"mailto:" + booking.user.notificationEmail} target="_top"
                                            className="email-link">{booking.user.notificationEmail}</a></span>
                                    </div>

                                    <div className="user-data">
                                        <span className="label">Phone: <a href={"tel:" + booking.user.phone}
                                                                          className="phone"><i
                                            className="fas fa-phone"/></a></span>
                                    </div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
