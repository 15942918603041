import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function createPromo() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/promo/createCode`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchPromosCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/promo/count`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchPromos() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/promo`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchPromo(promoId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/promo/${promoId}`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function updatePromo(promoId, payload) {
	return new Promise((resolve, reject) => {
		axios.patch(`${API_URL}/promo/${promoId}`, payload, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}
