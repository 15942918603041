import React, { Component } from 'react';
import { login, signin, logoutUser } from '../../actions/auth';
import './login.css';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			showWrongEmailOrPasswordError: false,
			showEmailRequiredError: false,
			showPasswordRequiredError: false,
			smsWasSent: false,
		};
	}

	handleSignin() {
		if (!this.state.email) return;
		signin(this.state.email)
			.then((res) => {
				this.setState({ smsWasSent: true });
			})
			.catch((err) => {
				if (!err.response) {
					console.log('No connection');
					return;
				}
				if (err.response.status) {
					this.setState({ showWrongEmailOrPasswordError: true });
				}
			});
	}

	handleLogin() {
		if (!this.state.email || !this.state.password) return;
		login(this.state.email, this.state.password)
			.then((res) => {
				localStorage.setItem('accessToken', res.headers['accesstoken']);
				if (
					res.data.role !== 'ADMIN' &&
					res.data.role !== 'SUPPORT' &&
					res.data.role !== 'MARKETING'
				) {
					logoutUser();
					this.setState({ showWrongEmailOrPasswordError: true });
					return;
				}
				localStorage.setItem(
					'user',
					JSON.stringify({
						id: res.data.id,
						role: res.data.role,
						email: res.data.email,
						firstName: res.data.firstName,
						lastName: res.data.lastName,
					})
				);
				this.props.history.push(`/admin/dashboard`);
				window.location.reload(true);
			})
			.catch((err) => {
				if (!err.response) {
					console.log('No connection');
					return;
				}
				if (err.response.status) {
					this.setState({ showWrongEmailOrPasswordError: true });
				}
			});
	}

	handleEmailChange(e) {
		this.setState(
			{
				showWrongEmailOrPasswordError: false,
				showEmailRequiredError: false,
				email: e.target.value,
			},
			() => {
				if (!this.state.email) {
					this.setState({ showEmailRequiredError: true });
				}
			}
		);
	}

	handlePasswordChange(e) {
		this.setState(
			{
				showWrongEmailOrPasswordError: false,
				showPasswordRequiredError: false,
				password: e.target.value,
			},
			() => {
				if (!this.state.password) {
					this.setState({ showPasswordRequiredError: true });
				}
			}
		);
	}

	handlePasswordKeyDown(e) {
		if (e.key === 'Enter') {
			this.handleLogin();
		}
	}

	render() {
		return (
			<div className="login">
				<div className="d-flex justify-content-center">
					<div className="card">
						<div>
							<div className="card-header">
								<h1>
									GiroCarShare<h3> Admin Panel</h3>
								</h1>
							</div>
							<div className="card-body">
								{this.state.showEmailRequiredError && (
									<span className="info-text">E-mail is required</span>
								)}
								<div className="input-group form-group">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fas fa-user" />
										</span>
									</div>
									<input
										type="text"
										onChange={(e) => this.handleEmailChange(e)}
										value={this.state.email}
										className="form-control"
										placeholder="Your Admin E-mail"
									/>
								</div>
								{this.state.showPasswordRequiredError && (
									<span className="info-text">Code is required</span>
								)}
								{this.state.smsWasSent && (
									<div className="input-group form-group">
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fas fa-key" />
											</span>
										</div>
										<input
											type="password"
											className="form-control"
											placeholder="Code from SMS"
											onChange={(e) => this.handlePasswordChange(e)}
											onKeyDown={(e) => this.handlePasswordKeyDown(e)}
											value={this.state.password}
										/>
									</div>
								)}
								{!this.state.smsWasSent && (
									<div className="form-group">
										<button
											className="btn float-right login_btn"
											onClick={() => this.handleSignin()}
										>
											Send OTP Code
										</button>
									</div>
								)}
								{this.state.smsWasSent && (
									<div className="form-group">
										<button
											className="btn float-right login_btn"
											onClick={() => this.handleLogin()}
										>
											Log In
										</button>
									</div>
								)}
								{this.state.showWrongEmailOrPasswordError && (
									<div className="error-msg">
										<span>Wrong email or code</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
