import React, { Component } from 'react';
import { fetchUser, fetchUserCars, patchUser, updateUser } from '../../actions/users';
import UserAddress from './userAddress';
import { Link, withRouter } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import './user-details.css';
import { fetchUserBookings } from '../../actions/bookings';
import { formatDate } from '../../utils/utils';
import * as moment from 'moment';

class User extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			bookings: [],
			cars: [],
		};
		this.userId = props.match.params['userId'];
	}

	componentDidMount() {
		this.refreshUser();
		this.refreshUserBookings();
		this.refreshUserCars();
	}

	refreshUser() {
		fetchUser(this.userId).then((user) => {
			this.setState({ user: user });
		});
	}

	refreshUserBookings() {
		fetchUserBookings(this.userId).then((bookings) => {
			this.setState({ bookings: bookings });
		});
	}

	handleChangeUserStatus(userStatus) {
		updateUser(this.userId, { status: userStatus })
			.then((res) => {
				toast.success('User status successfully changed');
				this.refreshUser();
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	refreshUserCars() {
		fetchUserCars(this.userId).then((userCars) => this.setState({ cars: userCars }));
	}

	handleSelectBooking(bookingId) {
		this.props.history.push('/admin/bookings/' + bookingId);
	}

	handleClickCar(carId) {
		this.props.history.push('/admin/cars/' + carId);
	}

	handleReviewUser(reviewed) {
		updateUser(this.userId, { reviewedBy: reviewed })
			.then((res) => {
				toast.info('User reviewed successfully!');
				this.refreshUser();
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	showReviewUser(user) {
		if (user.reviewedBy) {
			return 'REVIEWED';
		}
		return 'WAITING';
	}

	getAddress() {}

	handleChangeUserField(e, field) {
		if (
			field === 'governmentIdFrontImageUrl' ||
			field === 'governmentIdBackImageUrl' ||
			field === 'drivingLicenceFrontImageUrl' ||
			field === 'drivingLicenceBackImageUrl'
		) {
			const name = 'img_' + Date.now();
			const formData = new FormData();
			formData.append(name, e.target.files[0]);
		} else {
			let user = { ...this.state.user };
			if (field === 'birthDate') {
				user[field] = moment(e).valueOf();
			} else {
				user[field] = e.target.value;
			}
			this.setState({ user: user });
		}
	}

	handleSaveUserFields() {
		let payload = {
			personalId: this.state.user.personalId,
			driversLicenceId: this.state.user.driversLicenceId,
			notificationEmail: this.state.user.notificationEmail,
			firstName: this.state.user.firstName,
			lastName: this.state.user.lastName,
			birthDate: this.state.user.birthDate,
			phone: this.state.user.phone,
			isRC: this.state.user.isRC,
			gender: this.state.user.gender,
		};

		patchUser(this.userId, payload)
			.then((updatedUser) => {
				this.setState({ user: updatedUser });
				toast.success('User successfully updated.');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	render() {
		const currentUser = JSON.parse(localStorage.getItem('user'));

		let user = this.state.user;
		return (
			<div className="container-user-details card-container">
				<Link className="option-user-details" to={'/admin/users'}>
					<div className="back-btn">
						<i className="fas fa-long-arrow-alt-left" />
						Back
					</div>
				</Link>
				<hr />

				{user && (
					<div>
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="user-data input-fields">
										{user.imageUrl && (
											<img
												src={user.imageUrl}
												style={{ width: '200px' }}
												alt={'user'}
											/>
										)}
									</div>

									<h4>
										<u>User details</u>
									</h4>
									<ListGroup>
										<ListGroup.Item>
											<div className="user-data input-fields">
												<span className="label">
													<strong>First name:</strong>
												</span>
												<input
													value={user.firstName || ''}
													onChange={(e) =>
														this.handleChangeUserField(e, 'firstName')
													}
												/>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Last name:</strong>
												</span>
												<input
													value={user.lastName || ''}
													onChange={(e) =>
														this.handleChangeUserField(e, 'lastName')
													}
												/>
											</div>

											<div className="user-data">
												<span className="label">
													<strong>Gender:</strong>
												</span>

												<div className="radio">
													<label htmlFor="">MALE</label>
													<input
														type="radio"
														name="gender"
														value="MALE"
														onChange={(e) =>
															this.handleChangeUserField(e, 'gender')
														}
														checked={user.gender === 'MALE'}
													/>
												</div>

												<div className="radio">
													<label htmlFor="">FEMALE</label>
													<input
														type="radio"
														name="gender"
														value="FEMALE"
														onChange={(e) =>
															this.handleChangeUserField(e, 'gender')
														}
														checked={user.gender === 'FEMALE'}
													/>
												</div>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Birth date:</strong>
												</span>
												<DatePicker
													selected={user.birthDate || 0}
													onChange={(e) =>
														this.handleChangeUserField(e, 'birthDate')
													}
												/>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Email:</strong>
												</span>
												<a
													href={'mailto:' + user.email}
													target="_top"
													className="email-link"
												>
													{user.email}
												</a>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Notification email:</strong>
												</span>
												<input
													value={user.notificationEmail || ''}
													onChange={(e) =>
														this.handleChangeUserField(
															e,
															'notificationEmail'
														)
													}
												/>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Phone:</strong>
												</span>
												<input
													value={user.phone || ''}
													onChange={(e) =>
														this.handleChangeUserField(e, 'phone')
													}
												/>
												<a href={'tel:' + user.phone} className="phone">
													<i className="fas fa-phone" />
												</a>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Drivers licence Id:</strong>
												</span>
												<input
													value={user.driversLicenceId || ''}
													onChange={(e) =>
														this.handleChangeUserField(
															e,
															'driversLicenceId'
														)
													}
												/>
											</div>

											<div className="user-data input-fields">
												<span className="label">
													<strong>Tax number:</strong>
												</span>
												<input
													value={user.personalId || ''}
													onChange={(e) =>
														this.handleChangeUserField(e, 'personalId')
													}
												/>
											</div>

											<div className="user-data">
												<span className="label">
													<strong>Company account:</strong>
												</span>

												<div className="radio">
													<label htmlFor="">COMPANY</label>
													<input
														type="radio"
														name="isRC"
														value="true"
														onChange={(e) =>
															this.handleChangeUserField(e, 'isRC')
														}
														checked={user.isRC === 'true'}
													/>
												</div>

												<div className="radio">
													<label htmlFor="">PERSON</label>
													<input
														type="radio"
														name="isRC"
														value="false"
														onChange={(e) =>
															this.handleChangeUserField(e, 'isRC')
														}
														checked={user.isRC === 'false'}
													/>
												</div>
											</div>

											<div className="user-data input-fields">
												{(currentUser.role === 'SUPPORT' ||
													currentUser.role === 'ADMIN') && (
													<div className="buttons">
														<button
															onClick={() =>
																this.handleSaveUserFields()
															}
															className="btn btn-secondary save-btn"
														>
															SAVE
														</button>
													</div>
												)}
											</div>
										</ListGroup.Item>
									</ListGroup>

									<h4>
										<u>Address info</u>
									</h4>

									<div className="col">
										<div className="row">
											<div className="col-sm">
												<UserAddress user={user} />
											</div>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="images-container">
										<div className="col-sm">
											<ListGroup>
												<ListGroup.Item>
													<div className="row documents">
														<div className="col">
															<div className="user-data input-fields">
																<span className="label">
																	<strong>BALANCE:</strong>
																</span>
																<h3>
																	<span className="badge badge-pill badge-secondary">
																		<strong>
																			{user.balance.toFixed(
																				2
																			)}
																		</strong>
																	</span>
																</h3>
															</div>
															<div className="user-data input-fields">
																<span className="label">
																	<strong>STATUS:</strong>
																</span>
																<h3>
																	<span className="badge badge-pill badge-secondary">
																		<strong>
																			{user.status}
																		</strong>
																	</span>
																</h3>
															</div>
															<div className="user-data input-fields">
																{(currentUser.role === 'SUPPORT' ||
																	currentUser.role ===
																		'ADMIN') && (
																	<div className="buttons">
																		{user.status !==
																			'BANNED' && (
																			<button
																				className="btn btn-warning"
																				onClick={() =>
																					this.handleChangeUserStatus(
																						'BANNED'
																					)
																				}
																			>
																				BAN
																			</button>
																		)}
																		{user.status !==
																			'PENDING' && (
																			<button
																				className="btn btn-primary"
																				onClick={() =>
																					this.handleChangeUserStatus(
																						'PENDING'
																					)
																				}
																			>
																				PENDING
																			</button>
																		)}
																		{user.status !==
																			'VERIFIED' && (
																			<button
																				className="btn btn-success"
																				onClick={() =>
																					this.handleChangeUserStatus(
																						'VERIFIED'
																					)
																				}
																			>
																				VERIFY
																			</button>
																		)}
																	</div>
																)}
															</div>
														</div>
														<div className="col">
															<div className="user-data input-fields">
																<span className="label">
																	<strong>EARNINGS:</strong>
																</span>
																<h3>
																	<span className="badge badge-pill badge-secondary">
																		<strong>
																			{user.earnings.toFixed(
																				2
																			)}
																		</strong>
																	</span>
																</h3>
															</div>
															<div className="user-data input-fields">
																<span className="label">
																	<strong>REVIEWED:</strong>
																</span>
																<h3>
																	<span className="badge badge-pill badge-secondary">
																		<strong>
																			{this.showReviewUser(
																				user
																			)}
																		</strong>
																	</span>
																</h3>
															</div>
															<div className="user-data input-fields">
																{(currentUser.role === 'SUPPORT' ||
																	currentUser.role === 'ADMIN') &&
																	!user.reviewedBy && (
																		<div className="buttons">
																			<button
																				className="btn btn-info"
																				onClick={() =>
																					this.handleReviewUser(
																						'REVIEW'
																					)
																				}
																			>
																				CONFIRM USER
																			</button>
																		</div>
																	)}
															</div>
														</div>
													</div>
												</ListGroup.Item>
											</ListGroup>
										</div>
										<h4>{''}</h4>
										<h4>
											<u>Document images</u>
										</h4>
										<ListGroup>
											<ListGroup.Item>
												<div className="row documents">
													<div className="col">
														<div className="image-header">
															<h5>Selfie holding ID</h5>
														</div>

														<img
															className="crs"
															src={
																user.governmentIdFrontImageUrl +
																'?w=200'
															}
															onClick={() =>
																this.openImage(
																	user.governmentIdFrontImageUrl +
																		'?w=500'
																)
															}
															style={{
																maxWidth: '200px',
																maxHeight: '150px',
															}}
															alt={'Not Uploaded'}
														/>
													</div>
													<div className="col">
														<div className="image-header">
															<h5>Driving licence - FRONT & BACK</h5>
														</div>
														<div className="row">
															<div className="col-sm">
																<img
																	className="crs"
																	src={
																		user.drivingLicenceFrontImageUrl +
																		'?w=200'
																	}
																	onClick={() =>
																		this.openImage(
																			user.drivingLicenceFrontImageUrl +
																				'?w=500'
																		)
																	}
																	style={{
																		maxWidth: '200px',
																		maxHeight: '150px',
																	}}
																	alt={'Not Uploaded'}
																/>
															</div>
															<div className="col-sm">
																<img
																	className="crs"
																	src={
																		user.drivingLicenceBackImageUrl +
																		'?w=200'
																	}
																	onClick={() =>
																		this.openImage(
																			user.drivingLicenceBackImageUrl +
																				'?w=500'
																		)
																	}
																	style={{
																		maxWidth: '200px',
																		maxHeight: '150px',
																	}}
																	alt={'Not Uploaded'}
																/>
															</div>
														</div>
													</div>
												</div>
											</ListGroup.Item>
										</ListGroup>
									</div>

									<div className="row cars-container">
										<div className="col">
											<h4>
												<u>User cars</u>
											</h4>
											<ListGroup>
												<ListGroup.Item>
													<div className="users-car-table">
														<div className="users-car-table-header">
															<div className="users-car-table-header-data">
																<b>Brand</b>
															</div>
															<div className="users-car-table-header-data">
																<b>Model</b>
															</div>
															<div className="users-car-table-header-data">
																<b>Version</b>
															</div>
															<div className="users-car-table-header-data">
																<b>Year</b>
															</div>
														</div>
														{this.state.cars.map((car) => (
															<div
																className="users-car-table-row"
																key={car.id}
																onClick={() =>
																	this.handleClickCar(car.id)
																}
															>
																<div className="users-car-table-row-data">
																	{car.brand}
																</div>
																<div className="users-car-table-row-data">
																	{car.model}
																</div>
																<div className="users-car-table-row-data">
																	{car.version}
																</div>
																<div className="users-car-table-row-data">
																	{car.year}
																</div>
															</div>
														))}
													</div>
												</ListGroup.Item>
											</ListGroup>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				<hr />

				<div className="container bookings-container">
					<h4>
						<u>Bookings</u>
					</h4>
					<div className="users-bookings-table">
						<div className="users-bookings-table-header">
							<div className="users-bookings-table-header-data">
								<b>Owner/Customer</b>
							</div>
							<div className="users-bookings-table-header-data">
								<b>Brand</b>
							</div>
							<div className="users-bookings-table-header-data">
								<b>Model</b>
							</div>
							<div className="users-bookings-table-header-data">
								<b>Booking time</b>
							</div>
						</div>
						{this.state.bookings.map((booking) => (
							<div
								className="users-bookings-table-row"
								key={booking.id}
								onClick={() => this.handleSelectBooking(booking.id)}
							>
								<div className="users-bookings-table-row-data">
									<span>
										{booking.car.user.firstName} {booking.car.user.lastName} /{' '}
										{booking.user.firstName} {booking.user.lastName}
									</span>
								</div>
								<div className="users-bookings-table-row-data">
									{booking.car.brand}
								</div>
								<div className="users-bookings-table-row-data version">
									{booking.car.model}
								</div>
								<div className="users-bookings-table-row-data">
									{formatDate(booking.createdAt)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(User);
