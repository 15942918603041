import React, { Component } from 'react';
import PartnersTable from './partnerTable';
import Header from '../global/header';
import { createPartner } from '../../actions/partners';
import { toast } from 'react-toastify';

export default class Partners extends Component {
	constructor(props) {
		super(props);

		this.state = {
			partnerId: ''
		};
	}

	componentDidMount() {}

	handleCreatePartner(partnerId) {
		createPartner(partnerId)
			.then((res) => {
				toast.success('Partner created');
				window.location.reload();
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	handleChangePartnerField(e) {
		let partnerId = { ...this.state.partnerId };
		partnerId = e.target.value;
		this.setState({ partnerId: partnerId });
	}

	render() {
		return (
			<div className="dashboard">
				<div className="nav-container">
					<Header />
				</div>
				<div className="container-partners card-container">
					<div className="partner-data input-fields">
						<div className="buttons">
							<div className="partner-data input-fields">
								<span>Insert "userId" of partner user below</span>
								<br />
								<input onChange={(e) => this.handleChangePartnerField(e)} />
								<button
									onClick={() => this.handleCreatePartner(this.state.partnerId)}
									className="btn btn-primary save-btn"
								>
									ADD PARTNER
								</button>
							</div>
						</div>
					</div>
					<h3>Business partners</h3>

					<hr />
					<PartnersTable />
				</div>
			</div>
		);
	}
}
