import React, { Component } from 'react';
import { fetchPromo, updatePromo } from '../../actions/promotions';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './promo-details.css';
import * as moment from 'moment';

class Promotion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			promotion: null
		};
		this.promoId = props.match.params['promoId'];
	}

	componentDidMount() {
		this.refreshPromo();
	}

	refreshPromo() {
		fetchPromo(this.promoId).then((promotion) => {
			this.setState({ promotion: promotion });
		});
	}

	handleChangePromoField(e, field) {
		let promotion = { ...this.state.promotion };
		if (field === 'validFrom' || field === 'validTo') {
			promotion[field] = moment(e).valueOf();
		} else {
			promotion[field] = e.target.value;
		}
		this.setState({ promotion: promotion });
	}

	handleSavePromoFields() {
		let payload = {
			code: this.state.promotion.code,
			text: this.state.promotion.text,
			value: parseInt(this.state.promotion.value),
			usageNumber: parseInt(this.state.promotion.usageNumber),
			usageLimit: parseInt(this.state.promotion.usageLimit),
			validFrom: moment(this.state.promotion.validFrom, 'DD.MM.YYYY').valueOf() || 0,
			validTo: moment(this.state.promotion.validTo, 'DD.MM.YYYY').valueOf() || 0
		};

		updatePromo(this.promoId, payload)
			.then((updatedPromo) => {
				this.setState({ promotion: updatedPromo });
				toast.success('Promotion successfully updated.');
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	render() {
		let promotion = this.state.promotion;
		return (
			<div className="container-promo-details card-container">
				<Link className="option-promo-details" to={'/admin/promo'}>
					<div className="back-btn">
						<i className="fas fa-long-arrow-alt-left" />
						Back
					</div>
				</Link>
				<hr />

				{promotion && (
					<div>
						<div className="container">
							<div className="row">
								<div className="col">
									<h4>
										<u>Promotion details</u>
									</h4>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Code:</strong>
										</span>
										<input
											value={promotion.code || ''}
											onChange={(e) => this.handleChangePromoField(e, 'code')}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Text:</strong>
										</span>
										<input
											value={promotion.text || ''}
											onChange={(e) => this.handleChangePromoField(e, 'text')}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Value (% or €):</strong>
										</span>
										<input
											type="number"
											value={promotion.value || 0}
											onChange={(e) =>
												this.handleChangePromoField(e, 'value')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Usage number:</strong>
										</span>
										<input
											type="number"
											value={promotion.usageNumber || 0}
											onChange={(e) =>
												this.handleChangePromoField(e, 'usageNumber')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Usage limit:</strong>
										</span>
										<input
											type="number"
											value={promotion.usageLimit || 0}
											onChange={(e) =>
												this.handleChangePromoField(e, 'usageLimit')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Valid from:</strong>
										</span>

										<DatePicker
											selected={promotion.validFrom || 0}
											onChange={(e) =>
												this.handleChangePromoField(e, 'validFrom')
											}
										/>
									</div>

									<div className="user-data input-fields">
										<span className="label">
											<strong>Valid to:</strong>
										</span>

										<DatePicker
											selected={promotion.validTo || 0}
											onChange={(e) =>
												this.handleChangePromoField(e, 'validTo')
											}
										/>
									</div>

									<hr />

									<div className="user-data input-fields">
										<div className="buttons">
											<button
												onClick={() => this.handleSavePromoFields()}
												className="btn btn-secondary save-btn"
											>
												Save
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(Promotion);
