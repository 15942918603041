import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import './user-details.css';

export default class UserAddress extends Component {
	componentDidMount() {}

	render() {
		let user = this.props.user;
		if (!user.address || !user.address.addressObj) {
			return <div>User address info doesn't exist.</div>;
		}
		return (
			<ListGroup>
				<ListGroup.Item>
					<div className="user-data input-fields">
						<span className="label">
							<strong>STREET:</strong>
						</span>
						<span className="label">{user.address.addressObj.street || ''}</span>
					</div>
					<div className="user-data input-fields">
						<span className="label">
							<strong>HOUSE NR.:</strong>
						</span>
						<span className="label">{user.address.addressObj.houseNumber || ''}</span>
					</div>
					<div className="user-data input-fields">
						<span className="label">
							<strong>CITY:</strong>
						</span>
						<span className="label">{user.address.addressObj.city || ''}</span>
					</div>
					<div className="user-data input-fields">
						<span className="label">
							<strong>ZIP CODE:</strong>
						</span>
						<span className="label">{user.address.addressObj.postNumber || ''}</span>
					</div>
				</ListGroup.Item>
			</ListGroup>
		);
	}
}
