import React, { Component } from 'react';
import UsersTable from './usersTable';
import Header from '../global/header';

export default class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilter: {}
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="dashboard">
				<div className="nav-container">
					<Header />
				</div>
				<div className="container-users card-container">
					<h3>USER LIST</h3>
					<hr />
					<UsersTable />
				</div>
			</div>
		);
	}
}
